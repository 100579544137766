import React, { useState } from "react";
import { Image } from "./image";
import { Swiper, SwiperSlide } from "swiper/react";

// Import specific images for the pendant slider
import PendantImage1 from "../data/img/pendantImages/‏bitcoinPendant.png";
import PendantImage2 from "../data/img/pendantImages/originPedant.png";

// Import September images
import IMG_0212 from "../data/img/positions/2024/September/IMG_0212.PNG";
import IMG_0213 from "../data/img/positions/2024/September/IMG_0213.PNG";
import IMG_0214 from "../data/img/positions/2024/September/IMG_0214.PNG";
import IMG_0215 from "../data/img/positions/2024/September/IMG_0215.PNG";
import IMG_0216 from "../data/img/positions/2024/September/IMG_0216.PNG";
import IMG_0217 from "../data/img/positions/2024/September/IMG_0217.PNG";
import IMG_0218 from "../data/img/positions/2024/September/IMG_0218.PNG";
import IMG_0219 from "../data/img/positions/2024/September/IMG_0219.PNG";
import IMG_0220 from "../data/img/positions/2024/September/IMG_0220.PNG";
import IMG_0221 from "../data/img/positions/2024/September/IMG_0221.PNG";
import IMG_0222 from "../data/img/positions/2024/September/IMG_0222.PNG";

// Import October images
import IMG_0123 from "../data/img/positions/2024/October/IMG_0123.PNG";
import IMG_0124 from "../data/img/positions/2024/October/IMG_0124.PNG";
import IMG_0125 from "../data/img/positions/2024/October/IMG_0125.PNG";
import IMG_0126 from "../data/img/positions/2024/October/IMG_0126.PNG";
import IMG_0127 from "../data/img/positions/2024/October/IMG_0127.PNG";
import IMG_0128 from "../data/img/positions/2024/October/IMG_0128.PNG";
import IMG_0129 from "../data/img/positions/2024/October/IMG_0129.PNG";
import IMG_0130 from "../data/img/positions/2024/October/IMG_0130.PNG";
import IMG_0131 from "../data/img/positions/2024/October/IMG_0131.PNG";
import IMG_0132 from "../data/img/positions/2024/October/IMG_0132.PNG";
import IMG_0133 from "../data/img/positions/2024/October/IMG_0133.PNG";
import IMG_0134 from "../data/img/positions/2024/October/IMG_0134.PNG";
import IMG_0135 from "../data/img/positions/2024/October/IMG_0135.PNG";
import IMG_0136 from "../data/img/positions/2024/October/IMG_0136.PNG";
import IMG_0137 from "../data/img/positions/2024/October/IMG_0137.PNG";
import IMG_0138 from "../data/img/positions/2024/October/IMG_0138.PNG";
import IMG_0139 from "../data/img/positions/2024/October/IMG_0139.PNG";
import IMG_0140 from "../data/img/positions/2024/October/IMG_0140.PNG";

// Import November images
import IMG_0116 from "../data/img/positions/2024/November/IMG_0116.PNG";
import IMG_0117 from "../data/img/positions/2024/November/IMG_0117.PNG";
import IMG_0118 from "../data/img/positions/2024/November/IMG_0118.PNG";
import IMG_0119 from "../data/img/positions/2024/November/IMG_0119.PNG";
import IMG_0120 from "../data/img/positions/2024/November/IMG_0120.PNG";
import IMG_0121 from "../data/img/positions/2024/November/IMG_0121.PNG";
import IMG_0122 from "../data/img/positions/2024/November/IMG_0122.PNG";

const images = {
  2024: {
    September: [
      IMG_0212,
      IMG_0213,
      IMG_0214,
      IMG_0215,
      IMG_0216,
      IMG_0217,
      IMG_0218,
      IMG_0219,
      IMG_0220,
      IMG_0221,
      IMG_0222,
    ],
    October: [
      IMG_0123,
      IMG_0124,
      IMG_0125,
      IMG_0126,
      IMG_0127,
      IMG_0128,
      IMG_0129,
      IMG_0130,
      IMG_0131,
      IMG_0132,
      IMG_0133,
      IMG_0134,
      IMG_0135,
      IMG_0136,
      IMG_0137,
      IMG_0138,
      IMG_0139,
      IMG_0140,
    ],
    November: [
      IMG_0116,
      IMG_0117,
      IMG_0118,
      IMG_0119,
      IMG_0120,
      IMG_0121,
      IMG_0122,
    ],
  },
  2025: {},
};

// Define pendant images in a separate array
const pendantImages = [
  {
    src: PendantImage1,
    title: "Model",
    description: "Bitcoin Pendant"
  },
  {
    src: PendantImage2,
    title: "Type",
    description: "Exquisite craftsmanship for an elegant look."
  }
];

export const Gallery = (props) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [accessGranted, setAccessGranted] = useState(false);

  const handleYearClick = (year) => {
    if (!accessGranted) {
      const userPassword = prompt('Enter the password to view this section:');
      if (userPassword === 'DrBrokersTop') {
        setAccessGranted(true);
      } else {
        alert('Incorrect password!');
        return;
      }
    }
    setSelectedYear(year === selectedYear ? null : year);
    setSelectedMonth(null);
  };
  const handleMonthClick = (month) => {
    setSelectedMonth(month === selectedMonth ? null : month);
  };

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc); // Set the clicked image as the selected image
  };

  const closeImage = () => {
    setSelectedImage(null); // Clear the selected image to hide the large view
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Comunity Pendant</h2>
    {/* Swiper Slider for Pendant Images */}
    {/* Swiper Slider for Pendant Images */}
    <Swiper spaceBetween={20} slidesPerView={1} loop={true}>
            {pendantImages.map((pendant, index) => (
              <SwiperSlide key={index}>
                <div onClick={() => handleImageClick(pendant.src)} style={{ cursor: 'pointer' }}>
                  <img
                    src={pendant.src}
                    alt={pendant.title}
                    style={{
                      width: "10%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover"
                    }}
                  />
                  <h3>{pendant.title}</h3>
                  <p>{pendant.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <p><strong>Price: 5,000 USDT</strong></p>
          <ol>
                <li><strong>Handmade, available in yellow or white gold.</strong></li>
                <li><strong>symbolizing success in the Dr-Brokers community.</strong></li>
                <li><strong>Material:</strong> 14k gold</li>
                <li><strong>Weight:</strong> 10 grams</li>
                <li><strong>Size:</strong>3x2 cm </li>
                <li><strong>Inlay:</strong> 2 carat diamonds, VS quality.</li>
                <li><strong>Finish:</strong> Glossy and precise polishing.</li>
            </ol>

          <h2>Tools</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
 <div className="gallery">
            <div className="section-title">
              <h2>Positions</h2>
            </div>
            {Object.keys(images).map((year) => (
              <div key={year}>
                <h3
                  onClick={() => handleYearClick(year)}
                  style={{ cursor: "pointer" }}
                >
                  {year}
                </h3>
                {selectedYear === year && (
                  <div className="months">
                    {Object.keys(images[year]).map((month) => (
                      <div key={month}>
                        <h4
                          onClick={() => handleMonthClick(month)}
                          style={{ cursor: "pointer", paddingLeft: "20px" }}
                        >
                          {month}
                        </h4>
                        {selectedMonth === month && (
                          <div className="images" style={{ paddingLeft: "40px" }}>
                            {images[year][month].map((imgSrc, index) => (
                              <img
                                key={index}
                                src={imgSrc}
                                alt={`${month} ${index}`}
                                style={{ width: "100px", margin: "10px", cursor: "pointer" }}
                                onClick={() => handleImageClick(imgSrc)}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
                         {/* Full-size Image Overlay */}
                         {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={closeImage}
        >
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{ maxWidth: "90%", maxHeight: "90%", borderRadius: "8px" }}
          />
          <button
            onClick={closeImage}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              fontSize: "24px",
              background: "none",
              border: "none",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
};